<template>
  <div class="about">
    <v-sheet class="grayzelin text-center justify-center aling-center" height="150">
    <h1 class="text-center pt-10">Zelin Team</h1>
    <h4 class="text-center">
      Conoce un poco mas sobre las personas que hacen posible tus resultados
      digitales
    </h4>
    </v-sheet>
    <div class="mt-16">
      <h2 class="text-center">Founders</h2>
      <v-row justify="center" class="ma-10">
        <v-col cols="6" lg="4" v-for="i in founders" :key="i">
          <v-card class="pa-10 elevation-0">
            <v-card-text class="text-center">
              <v-avatar
                size="200"
              >
                <img
                  src="https://source.unsplash.com/random"
                  alt="John"
                >
              </v-avatar>
              <h1 class="my-4">
                Persona {{i.name}} 
              </h1> 
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <h2 class="text-center">Workers</h2>
      <v-row justify="center" class="ma-10">
        <v-col cols="12" md="3" v-for="i in workers" :key="i">
          <v-card class="pa-10  elevation-0">
            <v-card-text class="text-center">  
              <v-avatar
                size="150"
              >
                <img
                  src="https://source.unsplash.com/random"
                  alt="John"
                >
              </v-avatar>
              <h3 class="my-3">{{i.name}}</h3>
              <p>{{i.kind}}</p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      founders:[
        {name:"Carlos"},
        {name:"Samuel"},
        {name:"Alejandro"}
      ],
      workers:[
        {
          name:"Andrea",
          kind:"Diseño"        
        },
        {name:"Kike",kind:"Fotografía"},
        {name:"Wicho",kind:"Fotografía"},
        {name:"Cynthia",kind:"Community Manager"},
        {name:"Este puedes ser tu"}
      ]
    };
  },
};
</script>